import { get, post, put, del } from "../../helpers/apiWrapper";

// Get a list of all notifications
export const getAllNotifications = async (data) => {
  const config = {
    params: data,
  };
  return await get(`/notification`, config);
};

// Get a notification by id
export const getNotificationById = async (data) => {
  const config = {
    data: data,
  };
  return await get(`/notification/${config?.data}`);
};

// Create a notification
export const createNotification = async (notificationData) => {
  const config = {
    data: notificationData,
  };
  return await post(`/notification`, config);
};

// Update a notification
export const updateNotification = async (data) => {
  const config = {
    data: data?.notificationObject,
  };
  return await put(`/notification/${data?.data?.id}`, config);
};

// Delete a notification
export const deleteNotification = async (data) => {
  const config = {
    data: data,
  };
  return await del(`/notification/${data?.id}`);
};

export const getAllNotificationsUser = async (data) => {
  const config = {
    params: data,
  };
  return await post(`/notification/all`, config);
};