import { createSlice } from "@reduxjs/toolkit";
import {
  fetchNotificationListAction,
  getNotificationByIdAction,
  createNotificationAction,
  updateNotificationAction,
  deleteNotificationAction,
  fetchNotificationListUserAction
} from "./actions";
import { notification } from "antd";

const initialState = {
  createNotification: {},
  loadingCreate: false,
  errorCreate: null,

  updatedNotification: {},
  loadingEdit: false,
  errorEdit: null,

  notificationList: {},
  loadingList: false,
  errorNotificationList: null,

  getNotificationById: {},
  loadingNotificationById: false,
  errorGetNotificationById: null,

  deletedNotification: {},
  loadingDelete: false,
  errorDelete: null,

  notificationListUser: {},
  loadingListUser: false,
  errorNotificationListUser: null,
};

const notificationSlice = createSlice({
  name: "notification",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchNotificationListAction.pending, (state) => {
        state.loadingList = true;
        state.errorNotificationList = null;
      })
      .addCase(fetchNotificationListAction.fulfilled, (state, action) => {
        state.loadingList = false;
        state.notificationList = action.payload;
      })
      .addCase(fetchNotificationListAction.rejected, (state, action) => {
        state.loadingList = false;
        state.errorNotificationList = action.payload;
      })
      .addCase(fetchNotificationListUserAction.pending, (state) => {
        state.loadingListUser = true;
        state.errorNotificationListUser = null;
      })
      .addCase(fetchNotificationListUserAction.fulfilled, (state, action) => {
        state.loadingListUser = false;
        state.notificationListUser = action.payload;
      })
      .addCase(fetchNotificationListUserAction.rejected, (state, action) => {
        state.loadingListUser = false;
        state.errorNotificationListUser = action.payload;
      })
      .addCase(createNotificationAction.pending, (state) => {
        state.loadingCreate = true;
        state.errorCreate = null;
      })
      .addCase(createNotificationAction.fulfilled, (state, action) => {
        state.loadingCreate = false;
        state.createNotification = action.payload;

        if (action?.payload?.meta?.success === true) {
          notification.success({
            message: "Success",
            description: action?.payload?.meta?.message,
            duration: 2,
          });
        } else {
          notification.error({
            message: "Error",
            description: action?.payload?.message,
            duration: 2,
          });
        }
      })
      .addCase(createNotificationAction.rejected, (state, action) => {
        state.loadingCreate = false;
        state.errorCreate = action.payload;
      })
      .addCase(updateNotificationAction.pending, (state) => {
        state.loadingEdit = true;
        state.errorEdit = null;
      })
      .addCase(updateNotificationAction.fulfilled, (state, action) => {
        state.loadingEdit = false;
        state.updatedNotification = action.payload;
        state.notificationList.data = state?.notificationList?.data?.map(
          (notification) =>
            notification.id === action.payload?.data?.id
              ? action.payload?.data
              : notification
        );
        if (action?.payload?.meta?.success === true) {
          notification.success({
            message: "Success",
            description: action?.payload?.meta?.message,
            duration: 2,
          });
        } else {
          notification.error({
            message: "Error",
            description: action?.payload?.meta?.message,
            duration: 2,
          });
        }
      })
      .addCase(updateNotificationAction.rejected, (state, action) => {
        state.loadingEdit = false;
        state.errorEdit = action.payload;
      })
      .addCase(deleteNotificationAction.pending, (state) => {
        state.loadingDelete = true;
        state.errorDelete = null;
      })
      .addCase(deleteNotificationAction.fulfilled, (state, action) => {
        state.loadingDelete = false;
        state.deletedNotification = action.payload;
        state.notificationList.data = state?.notificationList?.data?.filter(
          (notification) => notification?.id !== action?.payload?.data?.id
        );
        state.notificationList.meta.pagination.totalNotifications -= 1;
        if (action?.payload?.meta?.success === true) {
          notification.success({
            message: "Success",
            description: action?.payload?.meta?.message,
            duration: 2,
          });
        } else {
          notification.error({
            message: "Error",
            description: action?.payload?.meta?.message,
            duration: 2,
          });
        }
      })
      .addCase(deleteNotificationAction.rejected, (state, action) => {
        state.loadingDelete = false;
        state.errorDelete = action.payload;
      })
      .addCase(getNotificationByIdAction.pending, (state) => {
        state.loadingNotificationById = true;
        state.errorGetNotificationById = null;
      })
      .addCase(getNotificationByIdAction.fulfilled, (state, action) => {
        state.loadingNotificationById = false;
        state.getNotificationById = action.payload;
      })
      .addCase(getNotificationByIdAction.rejected, (state, action) => {
        state.loadingNotificationById = false;
        state.errorGetNotificationById = action.payload;
      });
  },
});

export default notificationSlice.reducer;
