import { get, post, put, del,patch } from "../../helpers/apiWrapper";

//get a list of all the questions
export const getAllQuestion = async (data) => {
  const config = {
    params: data,
  };
  return await get(`/question`, config);
};

//get a question by id
export const getQuestionById = async (data) => {
  const config = {
    data: data,
  };
  return await get(`/question/${config?.data}`);
};

//create a question
export const createQuestion = async (questionData) => {
  const config = {
    data: questionData,
  };
  return await post(`/question`, config);
};

//update a question
export const updateQuestion = async (data) => {
  console.log("data: ", data);
  const config = {
    data: data?.questionObject,
  };
  console.log("url", `/question/${data?.data?.id}`);
  return await put(`/question/${data?.data?.id}`, config);
};

//delete a question
export const deleteQuestion = async (data) => {
  const config = {
    data: data,
  };
  return await del(`/question/${data?.id}`);
};

//Get All category
export const getCategoryList = async (data) => {
  const config = {
    data: data,
  };
  return await get(`/category/list`);
};

//get sub categort list
export const getSubCategoryList = async (data) => {
  console.log("data", data);
  return await get(`/category/list-by-parent/${data?.id}`);
};


// update page order
export const updatePageOrder = async (data) => {
  const config = {
    data: data,
  };
 
  return await patch(`/question/update`, config);
};
