import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  getAllQuestion,
  getQuestionById,
  createQuestion,
  updateQuestion,
  deleteQuestion,
  getCategoryList,
  getSubCategoryList,
  updatePageOrder
} from "../../api/questions";

export const fetchQuestionListAction = createAsyncThunk(
  "question/list",
  async (data, { rejectWithValue }) => {
    try {
      const response = await getAllQuestion(data);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getQuestionByIdAction = createAsyncThunk(
  "question/byId",
  async (data, { rejectWithValue }) => {
    try {
      const response = await getQuestionById(data);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const createQuestionAction = createAsyncThunk(
  "question/create",
  async (data, { rejectWithValue }) => {
    try {
      const response = await createQuestion(data);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateQuestionAction = createAsyncThunk(
  "question/edit",
  async (data, { rejectWithValue }) => {
    try {
      const response = await updateQuestion(data);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const deleteQuestionAction = createAsyncThunk(
  "question/delete",
  async (data, { rejectWithValue }) => {
    try {
      const response = await deleteQuestion(data);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getCategoryListAction = createAsyncThunk(
  "category/list",
  async (data, { rejectWithValue }) => {
    console.log("In the category list action");
    try {
      const response = await getCategoryList(data);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getSubCategoryListAction = createAsyncThunk(
  "category/sub-list",
  async (data, { rejectWithValue }) => {
    console.log("In the category list action");
    try {
      const response = await getSubCategoryList(data);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getCategoryListActionForList = createAsyncThunk(
  "category/list/filter",
  async (data, { rejectWithValue }) => {
    console.log("In the category list action");
    try {
      const response = await getCategoryList(data);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getSubCategoryListActionForList = createAsyncThunk(
  "category/sub-list/filter",
  async (data, { rejectWithValue }) => {
    console.log("In the category list action");
    try {
      const response = await getSubCategoryList(data);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const updatePageOrderAction = createAsyncThunk(
  "page/Order",
  async (data, { rejectWithValue }) => {
    console.log("In the category list action");
    try {
      const response = await updatePageOrder(data);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);


