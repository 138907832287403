import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  getAllNotifications,
  getNotificationById,
  createNotification,
  updateNotification,
  deleteNotification,
  getAllNotificationsUser
} from "../../api/notification";

export const fetchNotificationListAction = createAsyncThunk(
  "notification/list",
  async (data, { rejectWithValue }) => {
    try {
      const response = await getAllNotifications(data);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const fetchNotificationListUserAction = createAsyncThunk(
  "notification/listUser",
  async (data, { rejectWithValue }) => {
    try {
      const response = await getAllNotificationsUser(data);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getNotificationByIdAction = createAsyncThunk(
  "notification/byId",
  async (data, { rejectWithValue }) => {
    try {
      const response = await getNotificationById(data);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const createNotificationAction = createAsyncThunk(
  "notification/create",
  async (data, { rejectWithValue }) => {
    try {
      const response = await createNotification(data);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateNotificationAction = createAsyncThunk(
  "notification/edit",
  async (data, { rejectWithValue }) => {
    try {
      const response = await updateNotification(data);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const deleteNotificationAction = createAsyncThunk(
  "notification/delete",
  async (data, { rejectWithValue }) => {
    try {
      const response = await deleteNotification(data);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
