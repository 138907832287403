import { createSlice } from "@reduxjs/toolkit";
import {
  fetchQuestionListAction,
  getQuestionByIdAction,
  createQuestionAction,
  updateQuestionAction,
  deleteQuestionAction,
  getCategoryListAction,
  getSubCategoryListAction,
  getCategoryListActionForList,
  getSubCategoryListActionForList,
  updatePageOrderAction
} from "./actions";
import { notification } from "antd";

const initialState = {
  createQuestion: {},
  loadingCreate: false,
  errorCreate: null,

  updatedQuestion: {},
  loadingEdit: false,
  errorEdit: null,

  questionList: {},
  loadingList: false,
  errorQuestionList: null,

  getQuestionById: {},
  loadingQuestionById: false,
  errorGetQuestionById: null,

  deletedQuestion: {},
  loadingDelete: false,
  errorDelete: null,

  getCategoryList: {},
  loadingCategoryList: false,
  errorCategoryList: null,

  getSubCategoryList: {},
  loadingSubCategoryList: false,
  errorSubCategoryList: null,

  getCategoryListForFilter: {},
  getSubCategoryListForFilter: {},
};
const parentSlice = createSlice({
  name: "question",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchQuestionListAction.pending, (state) => {
        state.loadingList = true;
        state.errorQuestionList = null;
      })
      .addCase(fetchQuestionListAction.fulfilled, (state, action) => {
        state.loadingList = false;
        state.questionList = action.payload;
      })
      .addCase(fetchQuestionListAction.rejected, (state, action) => {
        state.loadingList = false;
        state.errorQuestionList = null;
      })
      .addCase(createQuestionAction.pending, (state) => {
        state.loadingCreate = true;
        state.errorCreate = null;
      })
      .addCase(createQuestionAction.fulfilled, (state, action) => {
        state.loadingCreate = false;
        state.createQuestion = action.payload;
        if (action?.payload?.meta?.success === true) {
          state.questionList.data = [
            action.payload?.data,
            ...state?.questionList?.data,
          ];
        }
        if (action?.payload?.meta?.success === true) {
          notification.success({
            message: "Success",
            description: action?.payload?.meta?.message,
            duration: 2,
          });
        } else {
          notification.error({
            message: "Error",
            description: action?.payload?.message,
            duration: 2,
          });
        }
      })
      .addCase(createQuestionAction.rejected, (state, action) => {
        state.loadingCreate = false;
        state.errorCreate = action.payload;
      })
      .addCase(updateQuestionAction.pending, (state) => {
        state.loadingEdit = true;
        state.errorEdit = null;
      })
      .addCase(updateQuestionAction.fulfilled, (state, action) => {
        state.loadingEdit = false;
        state.updatedQuestion = action.payload;
        // state.questionList.data = state?.questionList?.data?.map((macro) =>
        //   macro.id === action.payload?.data?.id ? action.payload?.data : macro
        // );
        if (action?.payload?.meta?.success === true) {
          notification.success({
            message: "Success",
            description: action?.payload?.meta?.message,
            duration: 2,
          });
        } else {
          notification.error({
            message: "Error",
            description: action?.payload?.meta?.message,
            duration: 2,
          });
        }
      })
      .addCase(updateQuestionAction.rejected, (state, action) => {
        state.loadingEdit = false;
        state.errorEdit = action.payload;
      })
      .addCase(deleteQuestionAction.pending, (state) => {
        state.loadingDelete = true;
        state.errorDelete = null;
      })
      .addCase(deleteQuestionAction.fulfilled, (state, action) => {
        state.loadingDelete = false;
        state.deletedQuestion = action.payload;
        state.questionList.data = state?.questionList?.data?.filter(
          (groupId) => groupId?.id !== action?.payload?.data?.id
        );
        state.questionList.meta.pagination.totalQuestions -= 1;
        if (action?.payload?.meta?.success === true) {
          notification.success({
            message: "Success",
            description: action?.payload?.meta?.message,
            duration: 2,
          });
        } else {
          notification.error({
            message: "Error",
            description: action?.payload?.meta?.message,
            duration: 2,
          });
        }
      })
      .addCase(deleteQuestionAction.rejected, (state, action) => {
        state.loadingDelete = false;
        state.errorDelete = action.payload;
      })
      //For Category List
      .addCase(getCategoryListAction.pending, (state) => {
        state.loadingCategoryList = true;
        state.errorCategoryList = null;
      })
      .addCase(getCategoryListAction.fulfilled, (state, action) => {
        state.loadingCategoryList = false;
        state.getCategoryList = action.payload;
      })
      .addCase(getCategoryListAction.rejected, (state, action) => {
        state.loadingCategoryList = false;
        state.errorCategoryList = null;
      })
      //For sub Category List
      .addCase(getSubCategoryListAction.pending, (state) => {
        state.loadingSubCategoryList = true;
        state.errorSubCategoryList = null;
      })
      .addCase(getSubCategoryListAction.fulfilled, (state, action) => {
        state.loadingSubCategoryList = false;
        state.getSubCategoryList = action.payload;
      })
      .addCase(getSubCategoryListAction.rejected, (state, action) => {
        state.loadingSubCategoryList = false;
        state.errorSubCategoryList = null;
      })
      //get question details by id
      .addCase(getQuestionByIdAction.pending, (state) => {
        state.loadingQuestionById = true;
        state.errorGetQuestionById = null;
      })
      .addCase(getQuestionByIdAction.fulfilled, (state, action) => {
        state.loadingQuestionById = false;
        state.getQuestionById = action.payload;
      })
      .addCase(getQuestionByIdAction.rejected, (state, action) => {
        state.loadingQuestionById = false;
        state.errorGetQuestionById = null;
      })
      .addCase(getCategoryListActionForList.pending, (state) => {})
      .addCase(getCategoryListActionForList.fulfilled, (state, action) => {
        state.getCategoryListForFilter = action.payload;
      })
      .addCase(getCategoryListActionForList.rejected, (state, action) => {})
      //For sub Category List
      .addCase(getSubCategoryListActionForList.pending, (state) => {})
      .addCase(getSubCategoryListActionForList.fulfilled, (state, action) => {
        state.getSubCategoryListForFilter = action.payload;
      })
      .addCase(getSubCategoryListActionForList.rejected, (state, action) => {})
      .addCase(updatePageOrderAction.pending, (state) => {
        
      })
      .addCase(updatePageOrderAction.fulfilled, (state, action) => {
     
        if (action?.payload?.meta?.success === true) {
          state.questionList.data = state.questionList.data.map((macro) =>
            macro.id === action.payload?.data?.id ? action.payload?.data : macro
          );
          notification.success({
            message: "Success",
            description: action?.payload?.meta?.message,
            duration: 2,
          });
        } else {
          notification.error({
            message: "Error",
            description: action?.payload?.meta?.message,
            duration: 2,
          });
        }
      })
      .addCase(updatePageOrderAction.rejected, (state, action) => {
        
      });
  },
});

export default parentSlice.reducer;
